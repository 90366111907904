import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import de from "locales/de/all.json";
import en from "locales/en/all.json";
/* import uk from "locales/uk/all.json";
import ar from "locales/ar/all.json";
import tr from "locales/tr/all.json"; */

const translationResources = {
  de: {
    translation: de,
  },
  en: {
    translation: en,
  },
  /*   ar: {
    translation: ar,
  },
  tr: {
    translation: tr,
  },
  uk: {
    translation: uk,
  }, */
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: translationResources,
    fallbackLng: "en_GB",
    debug: process.env.NODE_ENV === "development",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
