import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import './i18n';
import NoMore from 'components/NoMore';

process.env.NODE_ENV === 'production' &&
  Sentry.init({
    dsn: 'https://7e9a89f494354ec280a9e6e594c8b9bd@o583647.ingest.sentry.io/5737004',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,
  });

ReactDOM.render(
  <React.StrictMode>{true ? <NoMore /> : <App />}</React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
