const NoMore = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2rem',
        height: '100vh',
      }}>
      <div className='logo'>
        <svg
          height='100'
          width='100'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M18.2115 10.9872L20.4631 11.1164L19.7782 12.5301L18.2115 10.9872Z'
            fill='#FCA5A5'
          />
          <path
            d='M20.1176 11.5437L21.2757 11.4461L20.7922 12.4439L20.1176 11.5437Z'
            fill='#FCA5A5'
          />
          <path
            d='M22.1125 13.6151L20.1306 12.6035L21.3882 11.5947L22.1125 13.6151Z'
            fill='#DC2626'
          />
          <path
            d='M17.9834 12.4065L19.9316 11.4186L20.2215 12.9476L17.9834 12.4065Z'
            fill='#B91C1C'
          />
          <path
            d='M18.6539 13.5988L19.7432 11.7394L20.805 12.9301L18.6539 13.5988Z'
            fill='#EF4444'
          />
          <path
            d='M20.4728 11.2646L20.5112 12.022L19.8698 11.7723L20.4728 11.2646Z'
            fill='#EF4444'
          />
          <path
            d='M22.957 12.678L20.697 12.7128L21.2711 11.2564L22.957 12.678Z'
            fill='#F87171'
          />
          <path
            d='M20.655 13.9696L19.679 12.0034L21.333 11.9901L20.655 13.9696Z'
            fill='#F87171'
          />
          <path
            d='M22.2905 10.1818L21.273 12.0332L20.1215 10.9183L22.2905 10.1818Z'
            fill='#FECACA'
          />
          <path
            d='M20.7078 9.68423L21.4173 11.7187L19.7681 11.5996L20.7078 9.68423Z'
            fill='#7F1D1D'
          />
          <path
            d='M22.658 10.8621L21.4701 12.6893L20.4962 11.4341L22.658 10.8621Z'
            fill='#991B1B'
          />
          <path
            d='M20.0168 12.6585L20.0171 11.7229L20.7153 12.0118L20.0168 12.6585Z'
            fill='#991B1B'
          />
          <path
            d='M19.0573 9.93387L21.0252 11.0692L19.6537 11.9373L19.0573 9.93387Z'
            fill='#B91C1C'
          />
          <path
            d='M12.7496 12.195L3.6951 16.2291L3.15094 9.48827L12.7496 12.195Z'
            fill='#10B981'
          />
          <path
            d='M12.7703 12.2162L8.87712 20.887L3.68988 16.1986L12.7703 12.2162Z'
            fill='#34D399'
          />
          <path
            d='M12.7715 12.181L16.0942 21.0586L8.8972 20.8593L12.7715 12.181Z'
            fill='#6EE7B7'
          />
          <path
            d='M12.7533 12.1739L21.5557 16.673L16.0713 21.0531L12.7533 12.1739Z'
            fill='#A7F3D0'
          />
          <path
            d='M12.6588 12.2232L14.5991 2.99144L20.6909 6.59526L12.6588 12.2232Z'
            fill='#064E3B'
          />
          <path
            d='M12.7734 12.2163L7.56815 4.17516L14.6537 2.97358L12.7734 12.2163Z'
            fill='#065F46'
          />
          <path
            d='M12.7648 12.1929L3.17207 9.46788L7.59361 4.13248L12.7648 12.1929Z'
            fill='#047857'
          />
        </svg>
      </div>
      <p className='text-left text-lg'>
        Wir möchten Sie darüber informieren, dass Covify.de den Service zum
        01.04.2023 einstellt. Wir bedanken uns bei allen Kunden für ihre
        Unterstützung und ihr Vertrauen in unseren Service.
      </p>
      <p className='text-left text-lg'>
        Vielen Dank für Ihre Zusammenarbeit und Ihr Verständnis
      </p>
      <p>Covify.de</p>
    </div>
  );
};

export default NoMore;
