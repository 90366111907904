import React, {useEffect, useState} from 'react';
import {useRecoilState} from 'recoil';
import {useQuery} from 'react-query';
import {Button, Collapse, List} from 'antd';
import moment from 'moment';
import ReactGa from 'react-ga';
import {appointmentState, stepperState} from 'store/atoms';
import {AppointmentSlotResponse, AppointmentSlotsResponse} from 'types';
import {STEP_VALUE} from 'CONSTANTS';
import {getCookieConsentValue} from 'react-cookie-consent';
import {useTranslation} from "react-i18next";

const {Panel} = Collapse;

const SelectTimeSlot = () => {
    const [fetchedDates, setFetchedDates] = useState<AppointmentSlotsResponse[]>([]);
    const [page, setPage] = useState(0);
    const [appointment, setAppointmentState] = useRecoilState(appointmentState);
    const [stepState, setStepState] = useRecoilState(stepperState);
    const isConsent = getCookieConsentValue();
    const {t} = useTranslation();

    const {
        isLoading,
        isPreviousData,
        isFetching,
        refetch
    } = useQuery<AppointmentSlotsResponse[]>(`booking/organizations/${appointment?.organizationId}/appointments?page=${page}&size=7`, {
        keepPreviousData: true,
        retry: false,
        refetchOnWindowFocus: false,
        onSuccess: (data) => setFetchedDates(fetchedDates.concat(data)),
    });

    useEffect(() => {
        refetch();
    }, [page, refetch]);

    const handleSlotSelection = (
        selectedSlot: AppointmentSlotResponse,
        selectedDate: AppointmentSlotsResponse['date']
    ) => {
        if (isConsent === 'true') {
            ReactGa.event({
                category: 'Appointment',
                action: 'Selected slot',
            });
        }
        setAppointmentState({
            ...appointment,
            date: selectedDate,
            startTime: selectedSlot?.startTime,
            endTime: selectedSlot?.endTime,
        });

        setStepState(stepState + STEP_VALUE);
    };

    const onLoadMore = () => {
        if (fetchedDates) {
            setPage(page + 1);
        }
    };

    const loadMore =
        isPreviousData || !isFetching ? (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                }}
            >
                <Button loading={isFetching} onClick={onLoadMore}>
                    {t('MoreDates')}
                </Button>
            </div>
        ) : null;

    return (
        <div className="w-full px-4 py-16">
            <p>{t('SelectDate')}</p>
            <List
                loading={isLoading}
                dataSource={fetchedDates}
                loadMore={loadMore}
                style={{maxHeight: '80vh', overflowY: 'scroll'}}
                renderItem={(item, i) => {
                    return (
                        <>
                            <List.Item>
                                <Collapse style={{minWidth: '100%'}}>
                                    <Panel
                                        key={i}
                                        header={`${moment(item?.date, 'DD-MM-YYYY').format(
                                            'dddd'
                                        )}, ${moment(item?.date, 'DD-MM-YYYY').format(
                                            'L'
                                        )}`}
                                    >
                                        <List
                                            grid={{gutter: 16, column: 3}}
                                            dataSource={item?.slots}
                                            renderItem={(slot) => (
                                                <List.Item>
                                                    <Button
                                                        type='primary'
                                                        style={{
                                                            backgroundColor: '#10b981',
                                                            borderColor: '#10b981',
                                                            fontWeight: 700,
                                                        }}
                                                        onClick={() =>
                                                            handleSlotSelection(slot, item?.date)
                                                        }
                                                    >
                                                        {slot?.startTime}
                                                    </Button>
                                                </List.Item>
                                            )}
                                        />
                                    </Panel>
                                </Collapse>
                            </List.Item>
                        </>
                    );
                }}
            />
        </div>
    );
};

export default SelectTimeSlot;
