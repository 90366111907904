import { atom } from 'recoil';
import { CreateAppointmentRequest, OrganizationDTO } from 'types';

const stepperState = atom({
  key: 'stepperState',
  default: 0,
});

const appointmentState = atom<CreateAppointmentRequest>({
  key: 'appointmentState',
  default: {
    organizationId: undefined,
    date: undefined,
    startTime: undefined,
    endTime: undefined,
    appointmentType: 'BOOKING',
    proband: {
      firstname: '',
      lastname: '',
      birthDate: '',
      street: '',
      houseNumber: '',
      zip: '',
      city: '',
      country: '',
      email: '',
      phone: '',
    },
    reason: '',
    testType: { name: '', manufacturer: '' },
    privacyConsent: false,
    cwaConsent: false,
    cwaPrivacy: false,
  },
});

const selectionState = atom<OrganizationDTO>({
  key: 'selectionState',
  default: {},
});

export { stepperState, appointmentState, selectionState };
