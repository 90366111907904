import { useEffect, useState } from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { ConfigProvider } from "antd";
import axios from "axios";
import {
  QueryClient,
  QueryClientProvider,
  QueryFunctionContext,
} from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import "./App.css";
import Content from "components/Content";
import moment from "moment";
import "moment/locale/de";
import "moment/locale/en-gb";
import "moment/locale/tr";
import "moment/locale/ar";
import "moment/locale/uk";
import ScrollToTop from "components/ScrollToTop";
import CookieConsent, {
  Cookies,
  getCookieConsentValue,
} from "react-cookie-consent";
import { initGA } from "ga-utils";
import ReactGa from "react-ga";
import de_DE from "antd/lib/locale/de_DE";
import en_GB from "antd/lib/locale/en_GB";
/* import uk_UA from "antd/lib/locale/uk_UA";
import ar_EG from "antd/lib/locale/ar_EG";
import tr_TR from "antd/lib/locale/tr_TR"; */
import { useTranslation } from "react-i18next";

moment.locale("de");

const loadGoogleAdSense = () => {
  const existingScript = document.getElementById("adSense");

  if (!existingScript) {
    const script = document.createElement("script");
    script.src =
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2221645558526004";
    script.id = "adSense";
    script.async = true;
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);
    script.onload = () => {
      return true;
    };
  }
  if (existingScript) {
    return true;
  }
};

function App() {
  const { i18n } = useTranslation();
  const [locale, setLocale] = useState(de_DE);

  const defaultQueryFn = async (context: QueryFunctionContext) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/${context.queryKey}`
    );
    return data;
  };

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        queryFn: defaultQueryFn,
      },
    },
  });

  const handleAcceptCookie = () => {
    loadGoogleAdSense();
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }
  };

  const handleDeclineCookie = () => {
    loadGoogleAdSense();
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
      ReactGa.pageview(window.location.pathname + window.location.search);
    } else if (isConsent === "false") {
      handleDeclineCookie();
    }
  }, []);

  useEffect(() => {
    switch (i18n.language) {
      case "en":
        i18n.changeLanguage("en");
        setLocale(en_GB);
        moment.locale("en-gb");
        break;
      case "de":
        i18n.changeLanguage("de");
        setLocale(de_DE);
        moment.locale("de");
        break;
/*       case "uk":
        i18n.changeLanguage("uk");
        setLocale(uk_UA);
        moment.locale("uk");
        break;
      case "tr":
        i18n.changeLanguage("tr");
        setLocale(tr_TR);
        moment.locale("tr");
        break;
      case "ar":
        i18n.changeLanguage("ar");
        setLocale(ar_EG);
        moment.locale("ar");
        break; */
      default:
        i18n.changeLanguage("de");
        setLocale(de_DE);
        moment.locale("de");
        break;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <RecoilRoot>
      <Router>
        <ScrollToTop />
        <QueryClientProvider client={queryClient}>
          <ConfigProvider locale={locale}>
            <Content />
            <CookieConsent
              onAccept={handleAcceptCookie}
              onDecline={handleDeclineCookie}
              enableDeclineButton
              declineButtonText="Nur notwendige Cookies"
              buttonText="Einverstanden"
              style={{ background: "#831843", flexDirection: "column" }}
              contentStyle={{ flex: "1 0 0px" }}
              buttonWrapperClasses="button-wrapper"
              buttonStyle={{ backgroundColor: "#A7F3D0", color: "#064E3B" }}
              declineButtonStyle={{
                backgroundColor: "#FBCFE8",
                color: "#831843",
              }}
            >
              Diese Internetseite verwendet Cookies, Google Analytics und den
              für die Analyse und Statistik und Google AdSense für die Anzeige
              von nicht-personalisierten und bei Zustimmung personalisierten
              Werbeanzeigen. Cookies helfen uns, die Benutzerfreundlichkeit
              unserer Website zu verbessern. Durch die weitere Nutzung der
              Website stimmen Sie der Verwendung zu. Weitere Informationen
              hierzu finden Sie in unserer&nbsp;
              <Link to="/datenschutz">Datenschutzerklärung</Link>.
            </CookieConsent>
          </ConfigProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Router>
    </RecoilRoot>
  );
}

export default App;
