import { useState } from 'react';
import {
  Alert,
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Space,
} from 'antd';
import { RadioGroup } from '@headlessui/react';
import { useMutation, useQuery } from 'react-query';
import axios from 'axios';
import moment from 'moment';
import { OrganizationDTO, CreateAppointmentRequest, TestTypeDTO } from 'types';
import { DATE_FORMAT, TIME_FORMAT } from 'COVIFY_CONSTANTS';
import { ParamsOrganizationID } from './OrganizationPage';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function CheckIcon(props: any) {
  return (
    <svg viewBox='0 0 24 24' fill='none' {...props}>
      <circle cx={12} cy={12} r={12} fill='#fff' opacity='0.2' />
      <path
        d='M7 13l3 3 7-7'
        stroke='#fff'
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

const InstantBooking = () => {
  const [selectedTestType, setSelectedTestType] = useState<TestTypeDTO>();
  const { organizationId } = useParams<ParamsOrganizationID>();
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const orgaDetails = useQuery<OrganizationDTO>(
    `booking/organizations/${organizationId}`
  );

  const mutation = useMutation(
    async (newBooking: CreateAppointmentRequest) => {
      return axios.post(`${process.env.REACT_APP_API_URL}/booking`, newBooking);
    },
    {
      onError: () => {
        message.error(t('InstantBookingErrorMessage'));
      },
    }
  );

  const handleSeletion = (value: TestTypeDTO) => {
    setSelectedTestType(value);
  };

  const handleFinish = (values: CreateAppointmentRequest) => {
    if (values?.proband !== undefined) {
      values.date = moment(values.date).format(DATE_FORMAT);
      values.startTime = moment().format(TIME_FORMAT);
      values.endTime = moment().add(5, 'm').format(TIME_FORMAT);
      values.organizationId = orgaDetails?.data?.id;
      values.appointmentType = 'INSTANT';
      values.proband.id = '';
      values.testType = selectedTestType;
      values.reason = undefined;
    }
    mutation.mutate(values, {
      onSuccess: () => {
        form.resetFields();
        let secondsToGo = 5;
        const modal = Modal.success({
          title: t('InstantBookingSuccessMessage'),
          content: t('InstantBookingTimerMessage', { secondsToGo }),
        });
        const timer = setInterval(() => {
          secondsToGo -= 1;
          modal.update({
            content: t('InstantBookingTimerMessage', { secondsToGo }),
          });
        }, 1000);
        setTimeout(() => {
          clearInterval(timer);
          modal.destroy();
          window.scrollTo(0, 0);
        }, secondsToGo * 1000);
      },
      onError: () => {
        message.error(t('InstantBookingErrorMessage_2'));
      },
    });
  };

  return (
    <div className='flex w-full md:justify-center'>
      <div className='flex-col p-4 md:max-w-md'>
        <h1 className='text-2xl text-center font-bold'>
          {orgaDetails?.data?.name}
        </h1>
        <h4 className='text-lg text-center'>
          {orgaDetails?.data?.street} {orgaDetails?.data?.houseNumber},{' '}
          {orgaDetails?.data?.zip} {orgaDetails?.data?.city}
        </h4>
        <Form
          form={form}
          name='newAppointment'
          onFinish={handleFinish}
          requiredMark='optional'
          layout='vertical'
          scrollToFirstError
          initialValues={{
            privacyConsent: false,
            cwaConsent: false,
            cwaPrivacy: false,
          }}>
          <Divider type='horizontal' orientation='center'>
            {t('InstantBookingSection_1')}
          </Divider>
          <Form.Item
            name={['proband', 'firstname']}
            label={t('Firstname')}
            rules={[
              {
                required: true,
                message: t('CheckFirstname'),
              },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            name={['proband', 'lastname']}
            label={t('Lastname')}
            rules={[
              {
                required: true,
                message: t('CheckLastname'),
              },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            name={['proband', 'birthDate']}
            label={t('Birthdate')}
            hasFeedback
            rules={[
              {
                required: true,
                message: t('CheckBirthdate'),
              },
              {
                pattern: new RegExp(
                  /^(0[1-9]|[12][0-9]|3[01])[.](0[1-9]|1[012])[.](19|20)\d\d$/
                ),
                message: t('CheckBirthdate'),
              },
            ]}>
            <Input type='text' placeholder='TT.MM.JJJJ' />
          </Form.Item>
          <Space>
            <Form.Item
              name={['proband', 'street']}
              label={t('Street')}
              rules={[
                {
                  required: true,
                  message: t('CheckStreet'),
                },
              ]}>
              <Input />
            </Form.Item>
            <Form.Item
              name={['proband', 'houseNumber']}
              label={t('HouseNumber')}
              rules={[
                {
                  required: true,
                  message: t('CheckHouseNumber'),
                },
              ]}>
              <Input />
            </Form.Item>
          </Space>
          <Space>
            <Form.Item
              name={['proband', 'zip']}
              label={t('PostalCode')}
              rules={[
                {
                  required: true,
                  message: t('CheckPostalCode'),
                },
              ]}>
              <Input />
            </Form.Item>
            <Form.Item
              name={['proband', 'city']}
              label={t('City')}
              rules={[
                {
                  required: true,
                  message: t('CheckCity'),
                },
              ]}>
              <Input />
            </Form.Item>
          </Space>
          <Form.Item
            name={['proband', 'country']}
            label={t('Country')}
            rules={[
              {
                required: true,
                message: t('CheckCountry'),
              },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            name={['proband', 'email']}
            label={t('E-mail')}
            rules={[
              {
                type: 'email',
                message: t('CheckE-Mail'),
              },
              {
                required: true,
                message: t('CheckE-Mail'),
              },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            name='confirmEmail'
            label={t('E-Mail-Confirm')}
            dependencies={['proband', 'email']}
            hasFeedback
            rules={[
              {
                type: 'email',
                message: t('CheckE-MailCofirmation'),
              },
              {
                required: true,
                message: t('CheckE-MailCofirmation'),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue(['proband', 'email']) === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t('EmailNoMatch')));
                },
              }),
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            name={['proband', 'phone']}
            label={t('Phone')}
            rules={[
              {
                required: true,
                message: t('CheckPhone'),
              },
            ]}>
            <Input style={{ width: '100%' }} />
          </Form.Item>
          <Divider type='horizontal' orientation='center'>
            {t('TestType')}
          </Divider>
          <Form.Item
            name='testType'
            rules={[
              {
                required: true,
                message: t('CheckType'),
              },
            ]}>
            <RadioGroup value={selectedTestType} onChange={handleSeletion}>
              <RadioGroup.Label className='sr-only'>
                {t('TestType')}
              </RadioGroup.Label>
              <div className='space-y-2'>
                {orgaDetails.data?.testTypes?.map((type) => (
                  <RadioGroup.Option
                    key={type?.id}
                    value={type}
                    className={({ active, checked }) =>
                      `${
                        active
                          ? 'ring-2 ring-offset-2 ring-offset-green-300 ring-white ring-opacity-60'
                          : ''
                      }
                  ${
                    checked
                      ? 'bg-green-900 bg-opacity-75 text-white'
                      : 'bg-green-50'
                  }
                    relative rounded-lg shadow-md px-5 py-4 cursor-pointer flex focus:outline-none`
                    }>
                    {({ active, checked }) => (
                      <>
                        <div className='flex items-center justify-between w-full'>
                          <div className='flex items-center'>
                            <div className='text-sm'>
                              <RadioGroup.Label
                                as='p'
                                className={`text-left font-medium  ${
                                  checked ? 'text-white' : 'text-gray-900'
                                }`}>
                                {t(type.name ?? '')}
                              </RadioGroup.Label>
                              <RadioGroup.Description
                                as='span'
                                className={`inline ${
                                  checked ? 'text-green-100' : 'text-gray-500'
                                }`}>
                                <span>
                                  {type?.price === 0
                                    ? t('Free')
                                    : `${type?.price?.toFixed(2)} €`}
                                </span>{' '}
                                <span aria-hidden='true'>&middot;</span>{' '}
                                <span>{type?.description}</span>
                              </RadioGroup.Description>
                            </div>
                          </div>
                          {checked && (
                            <div className='flex-shrink-0 text-white'>
                              <CheckIcon className='w-6 h-6' />
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </Form.Item>

          {/* <>
            {selectedTestType?.name === 'PoC-Antigen-Schnelltest' &&
            selectedTestType.price === 0 ? (
              <Form.Item
                name='reason'
                label={t('To which exception group do you belong?')}
                help={t(
                  'People who belong to the exceptional group are entitled to a free test.'
                )}
                rules={[
                  { required: true, message: t('Please make a selection') },
                ]}>
                {isMobile ? (
                  <select
                    style={{
                      minHeight: '32px',
                      backgroundColor: '#fff',
                      border: '1px solid #d9d9d9',
                      borderRadius: '2px',
                      transition:
                        'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
                      fontSize: '14px',
                    }}>
                    <option value={undefined} selected disabled></option>
                    {Object.entries(freeReasons).map(([key, value]) => (
                      <option key={key} value={key}>
                        {t(value)}
                      </option>
                    ))}
                  </select>
                ) : (
                  <Select>
                    {Object.entries(freeReasons).map(([key, value]) => (
                      <Option
                        key={key}
                        value={key}
                        disabled={selectedTestType?.price!! !== 0}>
                        {t(value)}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            ) : null}
          </> */}
          <Divider type='horizontal' orientation='center'>
            {t('DataProcessingText2')}
          </Divider>
          <Form.Item
            name='privacyConsent'
            label={t('DataProcessing')}
            valuePropName='checked'
            rules={[
              {
                required: true,
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(new Error(t('DataProcessingWarning'))),
              },
            ]}>
            <Checkbox>{t('DataProcessingText1')}</Checkbox>
          </Form.Item>
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.testType !== curValues.testType
            }>
            {({ getFieldValue }) =>
              getFieldValue(['testType', 'name']) ===
              'PoC-Antigen-Schnelltest' ? (
                <>
                  <Divider type='horizontal' orientation='center'>
                    Corona-Warn-App
                  </Divider>
                  <Form.Item
                    name='cwaConsent'
                    label={
                      <Row align='middle'>
                        <img
                          height='auto'
                          width='50%'
                          src={`${process.env.PUBLIC_URL}/cwa_logo_word.svg`}
                          alt='Logo of CWA'
                        />
                        <span style={{ fontWeight: 700 }}>Corona-Warn-App</span>
                      </Row>
                    }
                    valuePropName='checked'>
                    <Checkbox>{t('CWALabel')}</Checkbox>
                  </Form.Item>
                  <Form.Item
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.cwaConsent !== curValues.cwaConsent
                    }>
                    {({ getFieldValue }) =>
                      getFieldValue('cwaConsent') ? (
                        <Form.Item
                          name='cwaPrivacy'
                          label={t('CWAText')}
                          extra={t('CWAHelpText')}
                          rules={[
                            {
                              required: true,
                              message: t('CWAWarning'),
                            },
                          ]}>
                          <Radio.Group>
                            <Radio
                              value={false}
                              style={{ marginBottom: '1rem' }}>
                              <strong style={{ textTransform: 'uppercase' }}>
                                {t('CWANamedLabel')}
                              </strong>
                              <br />
                              {t('CWANamedText')}
                            </Radio>
                            <Radio value={true}>
                              <strong style={{ textTransform: 'uppercase' }}>
                                {t('CWANonNamedLabel')}
                              </strong>
                              <br />
                              {t('CWANonNamedText')}
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      ) : null
                    }
                  </Form.Item>
                  <Alert
                    showIcon
                    type='warning'
                    message={t(
                      'Please bring your proof to get tested without co-payment.'
                    )}
                  />
                </>
              ) : null
            }
          </Form.Item>
          <Divider type='horizontal' orientation='center'>
            {t('InstantBookingSection_3')}
          </Divider>
          <Alert
            type='info'
            message={t('InstantBookingMessage')}
            showIcon
            description={t('InstantBookingDescription')}
          />
          <Button
            type='primary'
            htmlType='submit'
            style={{ width: '100%', marginTop: 5 }}
            loading={mutation.isLoading}>
            {t('InstantBookingButton')}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default InstantBooking;
