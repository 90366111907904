import {
  Input,
  Form,
  Space,
  Button,
  Checkbox,
  Radio,
  Row,
  Modal,
  Descriptions,
} from 'antd';
import { useRecoilState } from 'recoil';
import ReactGa from 'react-ga';
import { appointmentState, stepperState } from 'store/atoms';
import { CreateAppointmentRequest, ProbandDTO } from 'types';
import { STEP_VALUE } from 'CONSTANTS';
import { getCookieConsentValue } from 'react-cookie-consent';
import { storage } from 'helpers';
import { useMount } from 'ahooks';
import { useTranslation } from 'react-i18next';

const { confirm } = Modal;

export const freeReasons = {
  'Besucher von Krankenhäusern, Rehabilitations- und Pflegeeinrichtungen':
    'Visitors to hospitals, rehabilitation and care facilities',
  'Personal in medizinischen Einrichtungen': 'Staff in medical facilities',
};

const ProbandForm = () => {
  const [form] = Form.useForm();
  const [appointment, setAppointment] = useRecoilState(appointmentState);
  const [stepState, setStepState] = useRecoilState(stepperState);
  const isConsent = getCookieConsentValue();
  const localUserForm = storage.getLocalUserForm();
  const { t } = useTranslation();

  useMount(() => {
    if (form.getFieldValue('firstname') === '' && localUserForm) {
      confirm({
        title: t('ApplyData'),
        content: (
          <Descriptions
            bordered
            layout='horizontal'
            column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
            <Descriptions.Item label={t('Fullname')}>
              {localUserForm.firstname} {localUserForm.lastname}
            </Descriptions.Item>
            <Descriptions.Item label={t('Birthdate')}>
              {localUserForm.birthDate}
            </Descriptions.Item>
            <Descriptions.Item label={t('Address')}>
              {localUserForm.street} {localUserForm.houseNumber},&nbsp;
              {localUserForm.zip}&nbsp;
              {localUserForm.city}
            </Descriptions.Item>
            <Descriptions.Item label={t('E-Mail')}>
              {localUserForm.email}
            </Descriptions.Item>
          </Descriptions>
        ),
        onOk() {
          form.setFields([
            { name: 'firstname', value: localUserForm.firstname },
            { name: 'lastname', value: localUserForm.lastname },
            { name: 'birthDate', value: localUserForm.birthDate },
            { name: 'street', value: localUserForm.street },
            { name: 'houseNumber', value: localUserForm.houseNumber },
            { name: 'zip', value: localUserForm.zip },
            { name: 'city', value: localUserForm.city },
            { name: 'country', value: localUserForm.country },
            { name: 'email', value: localUserForm.email },
            { name: 'phone', value: localUserForm.phone },
            { name: 'reason', value: localUserForm.reason },
            { name: 'rememberUser', value: localUserForm.rememberUser },
          ]);
          if (isConsent === 'true') {
            ReactGa.event({
              category: 'Appointment',
              action: 'Auto-filled local remembered user data',
            });
          }
        },
        onCancel() {
          storage.removeAllTokens();
          if (isConsent === 'true') {
            ReactGa.event({
              category: 'Appointment',
              action: 'Deleted local remembered user data',
            });
          }
        },
      });
    }
  });

  const onFinish = (
    values: ProbandDTO &
      CreateAppointmentRequest & { symptomFree?: boolean } & {
        confirmEmail?: string;
      } & { consent?: boolean } & { privacy?: boolean } & {
        rememberUser?: boolean;
      }
  ) => {
    values.reason = undefined;

    if (isConsent === 'true') {
      ReactGa.event({
        category: 'Appointment',
        action: 'Filled out form',
      });
    }
    const consent = values?.privacyConsent;
    const cwaConsent = values?.consent;
    const cwaPrivacy = values?.privacy;
    delete values.confirmEmail;
    delete values.symptomFree;
    delete values?.privacyConsent;
    delete values?.cwaConsent;
    delete values?.cwaPrivacy;
    if (values.rememberUser) {
      storage.setLocalUserForm(values);
      if (isConsent === 'true') {
        ReactGa.event({
          category: 'Appointment',
          action: 'Enabled local remember me',
        });
      }
    } else {
      storage.removeAllTokens();
      if (isConsent === 'true') {
        ReactGa.event({
          category: 'Appointment',
          action: 'Disabled local remember me',
        });
      }
    }
    delete values?.rememberUser;
    values.id = '';
    setAppointment({
      ...appointment,
      reason: values?.reason,
      proband: values,
      privacyConsent: consent,
      cwaConsent: cwaConsent,
      cwaPrivacy: cwaPrivacy,
    });
    setStepState(stepState + STEP_VALUE);
  };

  return (
    <Form
      form={form}
      name='register'
      onFinish={onFinish}
      initialValues={{
        firstname: appointment?.proband?.firstname,
        lastname: appointment?.proband?.lastname,
        birthDate: appointment?.proband?.birthDate,
        street: appointment?.proband?.street,
        houseNumber: appointment?.proband?.houseNumber,
        zip: appointment?.proband?.zip,
        city: appointment?.proband?.city,
        country: appointment?.proband?.country,
        email: appointment?.proband?.email ?? '',
        phone: appointment?.proband?.phone,
        privacyConsent: appointment?.privacyConsent,
        consent: appointment?.cwaConsent,
        privacy: appointment?.cwaPrivacy,
        reason: appointment?.reason,
      }}
      scrollToFirstError
      style={{ width: '100%', textAlign: 'left' }}
      layout='vertical'
      requiredMark={t('Optional')}>
      <Form.Item
        name='firstname'
        label={t('Firstname')}
        rules={[
          {
            required: true,
            message: t('CheckFirstname'),
          },
        ]}>
        <Input />
      </Form.Item>
      <Form.Item
        name='lastname'
        label={t('Lastname')}
        rules={[
          {
            required: true,
            message: t('CheckLastname'),
          },
        ]}>
        <Input />
      </Form.Item>
      <Form.Item
        name='birthDate'
        label={t('Birthdate')}
        hasFeedback
        rules={[
          {
            required: true,
            message: t('CheckBirthdate'),
          },
          {
            pattern: new RegExp(
              /^(0[1-9]|[12][0-9]|3[01])[.](0[1-9]|1[012])[.](19|20)\d\d$/
            ),
            message: t('CheckBirthdate'),
          },
        ]}>
        <Input type='text' placeholder='TT.MM.JJJJ' />
      </Form.Item>
      <Space>
        <Form.Item
          name='street'
          label={t('Street')}
          rules={[
            {
              required: true,
              message: t('CheckStreet'),
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          name='houseNumber'
          label={t('HouseNumber')}
          rules={[
            {
              required: true,
              message: t('CheckHouseNumber'),
            },
          ]}>
          <Input />
        </Form.Item>
      </Space>
      <Form.Item
        name='zip'
        label={t('PostalCode')}
        rules={[
          {
            required: true,
            message: t('CheckPostalCode'),
          },
        ]}>
        <Input />
      </Form.Item>
      <Form.Item
        name='city'
        label={t('City')}
        rules={[
          {
            required: true,
            message: t('CheckCity'),
          },
        ]}>
        <Input />
      </Form.Item>
      <Form.Item
        name='country'
        label={t('Country')}
        rules={[
          {
            required: true,
            message: t('CheckCountry'),
          },
        ]}>
        <Input />
      </Form.Item>
      <Form.Item
        name='email'
        label={t('E-Mail')}
        hasFeedback
        rules={[
          {
            type: 'email',
            message: t('CheckE-Mail'),
          },
          {
            required: true,
            message: t('CheckE-Mail'),
          },
        ]}>
        <Input />
      </Form.Item>
      <Form.Item
        name='confirmEmail'
        label={t('E-Mail-Confirm')}
        dependencies={['email']}
        hasFeedback
        rules={[
          {
            type: 'email',
            message: t('CheckE-Mail'),
          },
          {
            required: true,
            message: t('CheckE-Mail'),
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('email') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t('CheckE-MailConfirmation')));
            },
          }),
        ]}>
        <Input />
      </Form.Item>
      <Form.Item
        name='phone'
        label={t('Phone')}
        rules={[
          {
            required: true,
            message: t('CheckPhone'),
          },
        ]}>
        <Input style={{ width: '100%' }} />
      </Form.Item>
      {/* <>
        {appointment.testType?.name === 'PoC-Antigen-Schnelltest' &&
        appointment.testType.price === 0 ? (
          <Form.Item
            name='reason'
            label={t('To which exception group do you belong?')}
            help={t(
              'People who belong to the exceptional group are entitled to a free test.'
            )}
            rules={[{ required: true, message: t('Please make a selection') }]}>
            {isMobile ? (
              <select
                style={{
                  minHeight: '32px',
                  backgroundColor: '#fff',
                  border: '1px solid #d9d9d9',
                  borderRadius: '2px',
                  transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
                  fontSize: '14px',
                }}>
                <option value={undefined} selected disabled></option>
                {Object.entries(freeReasons).map(([key, value]) => (
                  <option key={key} value={key}>
                    {t(value)}
                  </option>
                ))}
              </select>
            ) : (
              <Select>
                {Object.entries(freeReasons).map(([key, value]) => (
                  <Option
                    key={key}
                    value={key}
                    disabled={appointment.testType?.price!! !== 0}>
                    {t(value)}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        ) : null}
      </> */}
      <Form.Item
        style={{ marginTop: '1rem' }}
        name='privacyConsent'
        label={t('DataProcessing')}
        valuePropName='checked'
        rules={[
          {
            required: true,
            validator: (_, value) =>
              value
                ? Promise.resolve()
                : Promise.reject(new Error(t('DataProcessingWarning'))),
          },
        ]}>
        <Checkbox>
          {t('DataProcessingText1')}
          <a
            className='text-blue-500 underline'
            href='/datenschutz'
            target='_blank'>
            {t('DataProcessingText2')}
          </a>
          {t('DataProcessingText3')}
        </Checkbox>
      </Form.Item>
      <>
        {appointment.testType?.name === 'PoC-Antigen-Schnelltest' ? (
          <>
            <Form.Item
              name='symptomFree'
              label={t('ConfirmationSymptoms')}
              valuePropName='checked'
              rules={[
                {
                  required: true,
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(t('ConfirmationSymptomsWarning'))
                        ),
                },
              ]}>
              <Checkbox>{t('ConfirmationSymptomsText')}</Checkbox>
            </Form.Item>

            <Form.Item
              name='consent'
              label={
                <Row align='middle'>
                  <img
                    height='auto'
                    width='50%'
                    src={`${process.env.PUBLIC_URL}/cwa_logo_word.svg`}
                    alt='Logo of CWA'
                  />
                  <span style={{ fontWeight: 700 }}>Corona-Warn-App</span>
                </Row>
              }
              valuePropName='checked'>
              <Checkbox>{t('CWALabel')}</Checkbox>
            </Form.Item>
            <Form.Item
              shouldUpdate={(prevValues, curValues) =>
                prevValues.consent !== curValues.consent
              }>
              {({ getFieldValue }) =>
                getFieldValue('consent') ? (
                  <Form.Item
                    name='privacy'
                    label={t('CWAText')}
                    help={t('CWAHelpText')}
                    rules={[
                      {
                        required: true,
                        message: t('CWAWarning'),
                      },
                    ]}>
                    <Radio.Group>
                      <Radio value={false} style={{ marginBottom: '1rem' }}>
                        <strong style={{ textTransform: 'uppercase' }}>
                          {t('CWANamedLabel')}
                        </strong>
                        <br />
                        {t('CWANamedText')}
                      </Radio>
                      <Radio value={true}>
                        <strong style={{ textTransform: 'uppercase' }}>
                          {t('CWANonNamedLabel')}
                        </strong>
                        <br />
                        {t('CWANonNamedText')}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                ) : null
              }
            </Form.Item>
          </>
        ) : null}
        <Form.Item
          name='rememberUser'
          label={t('SaveDataLabel')}
          valuePropName='checked'
          help={<p>{t('SaveDataText')}</p>}>
          <Checkbox>{t('SaveDataAgree')}</Checkbox>
        </Form.Item>
      </>
      <Form.Item
        style={{
          textAlign: 'center',
        }}>
        <Button
          type='primary'
          htmlType='submit'
          style={{ backgroundColor: '#10b981', borderColor: '#10b981' }}>
          {t('Confirm')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ProbandForm;
